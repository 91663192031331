import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { AppBar, CircularProgress, Box, useMediaQuery, Theme, TextField, Button } from '@mui/material';
import NavBar from '../Navigation/Navigation';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';

interface TransferDetail {
  receivingLocation: string;
  SendingLocation: string;
  Transfer_No: string;
  Notes: string;
  Status: string;
  Qty_Send: number;
  Qty_Received: number;
  Send_Date: string;
  Received_Date: string | "";
}

const TransferView: React.FC = () => {
  const location = useLocation();
  const [transferData, setTransferData] = useState<TransferDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const navigate = useNavigate();
  const [transferNo, setTransferNo] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const params = new URLSearchParams(location.search);
  const status = params.get('status') || 'sent';

  // Debounced fetch function
  const fetchTransfers = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await fetch('/trTracking/trView', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status, page, pageSize, transferNo }),
      });
      const data = await response.json();
      setTransferData(data.records);
      setRowCount(data.totalRecords);
    } catch (error) {
      console.error('Error fetching transfer data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTransfers(paginationModel.page, paginationModel.pageSize);
    }, 1000); // 1000ms debounce

    return () => clearTimeout(handler);
  }, [status, paginationModel, transferNo]);

  const handleTransferNoSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransferNo(e.target.value);
    setPaginationModel({ page: 0, pageSize: 50 });
  };

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'MM-dd-yyyy hh:mm a');
  };

  const columns: GridColDef[] = [
    { field: 'Transfer_No', headerName: '#TR_No', width: 130 },
    { field: 'receivingLocation', headerName: 'Receiving_Loc', width: 165 },
    { field: 'SendingLocation', headerName: 'Sending_Loc', width: 165 },
    { field: 'Qty_Send', headerName: 'Qty_Sent', width: 100 },
    { field: 'Qty_Received', headerName: 'Qt_RCV', width: 100 },
    { field: 'Status', headerName: 'Status', width: 110 },
    {
      field: 'Send_Date',
      headerName: 'Sent Date',
      width: 190,
      valueGetter: (params) => formatDate(params.row.Send_Date),
    },
    {
      field: 'Received_Date',
      headerName: 'Received Date',
      width: 190,
      valueGetter: (params) => formatDate(params.row.Received_Date),
    },
    { field: 'Notes', headerName: 'Notes', flex: 1 },
  ];

  const handleRowClick = (params: any) => {
    const transferNo = params.row.Transfer_No;
    const SendingLocation = params.row.SendingLocation;
    const receivingLocation = params.row.receivingLocation;
    
    navigate(`/transfer-details/${transferNo}/${SendingLocation}/${receivingLocation}`);
  };

  return (
    <>
      <AppBar position='sticky'>
        <NavBar />
      </AppBar>
      <Box
        sx={{
          height: isSmallScreen ? '80vh' : '90vh', 
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
           sx={{
            height: isSmallScreen ? '70vh' : '82vh', // Adjust height based on screen size
            width: '100%',
            '& .MuiDataGrid-root': {
              border: '1px solid #ccc', // Border for the grid
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#FFFFF0', // Header row background color
              fontSize: '1.2rem', // Header font size
              fontWeight: 'bold', // Header font weight
              borderBottom: '1px solid #ccc',
              
            },
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #ccc', // Add border between cells
              borderRight: '1px solid #ccc',
            },
            '& .MuiDataGrid-cell:last-child': {
              borderRight: 'none', // Remove right border for the last cell in the row
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: '#D3D3D3',
              fontSize: '1rem', // Odd row background color
              cursor: 'pointer'
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#FAF9F6', // Even row background color
              fontSize:'1rem',
              cursor:'pointer'
            },
          }}
        >
          <DataGrid
            rows={transferData}
            columns={columns}
            pagination
            pageSizeOptions={[50]}
            rowCount={rowCount}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.Transfer_No}
            loading={loading}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
          }}
        >
          <TextField
            label="Search by Transfer Number"
            variant="outlined"
            value={transferNo || ''}
            onChange={handleTransferNoSearch}
            sx={{width:'100%' }}
          />
         
        </Box>
      </Box>
    </>
  );
};

export default TransferView;
