import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


interface ReportGeneratorDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const InventoryDetailsByStyles: React.FC<ReportGeneratorDialogProps> = ({ open, setOpen }) => {
  const [styleCode, setStyleCode] = useState<string>('');
  const [location, setLocation] = useState<string>('DC-Caledonia');

  function getInitialStartDate(): string {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0];
  }

  // Reset form when dialog is opened
  useEffect(() => {
    if (open) {
      setStyleCode('');
      setLocation('');
    }
  }, [open]);

  const handleClose = () => {
    // Reset the states to their initial values upon closing the dialog
    setStyleCode('');
    setLocation('');
    setOpen(false);
  };


 



  const handlePrint = async () => {
    const reportWindow = window.open('', '_blank');
    if (!reportWindow) {
        alert('Failed to open new window. Please check your popup blocker settings.');
        return;
    }
    reportWindow.document.write('Loading report...'); // Provide immediate feedback

    try {
        // Fetch the PDF via POST request
        const response = await fetch('/invReport', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ styleCode, location }) // Send styleCode in the body
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            // Redirect the new window to the blob URL to display the PDF
            reportWindow.location.href = url;
        } else {
            reportWindow.close(); // Close the new window if the fetch failed
            alert('Failed to generate report');
        }
    } catch (error) {
        console.error('Error generating report:', error);
        reportWindow.close(); // Ensure to close the new window in case of error
        alert('An error occurred while generating the report.');
    }
};





  return (



    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>InventoryDetails By Style</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="styleCode"
          label="Style"
          type="text"
          fullWidth
          variant="outlined"
          value={styleCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStyleCode(e.target.value)}
        />
        <TextField
          margin="dense"
          id="location"
          label="Location"
          type="text"
          fullWidth
          variant="outlined"
          value={location}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocation(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handlePrint}>Submit</Button>
      </DialogActions>
    </Dialog>

  );
};

export default InventoryDetailsByStyles;
