import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Grid, TextField, AppBar, FormControl, InputLabel, Select, MenuItem, Typography, Button } from '@mui/material';
import NavBar from '../Navigation/Navigation';
import PrintTagsButton from './PrintTags';


interface TransferDetail {
  receivingLocation: string;
  SendingLocation: string;
  PLU_SKU: string;
  Description_1: string;
  Style_Code: string;
  Size1_Code: string;
  Color_Code: string;
  QTY_Send: number;    // Updated field names
  QTY_Received: number; // Updated field names
  Status: string;
  Send_Date: string;
  Received_Date: string;
  Transfer_No: number;
  List_Notes: string;
  Notes: string;
  Cost: number;
  Sale_Price: number;
}

const TransferDetails: React.FC = () => {
    const { transferNo, SendingLocation, receivingLocation } = useParams<{ transferNo: string, SendingLocation: string, receivingLocation: string }>(); // Extract all params
  const [transferDetails, setTransferDetails] = useState<TransferDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterKey, setFilterKey] = useState<'PLU_SKU' | 'Style_Code' | 'Size1_Code' | 'Description_1'>('Style_Code'); // Added Description_1 for filtering
  const [searchValue, setSearchValue] = useState<string>(''); // Search value

  // Fetch transfer details using useEffect
  useEffect(() => {
    const fetchTransferDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch('/trTracking/trDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ transferNo, SendingLocation, receivingLocation }), // Send transferNo, SendingLocation, and receivingLocation
        });
        const data = await response.json();
        setTransferDetails(data);
      } catch (error) {
        console.error('Error fetching transfer details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransferDetails();
  }, [transferNo, SendingLocation, receivingLocation]);

  // Aggregated function to calculate total quantities
  const getTotalQty = (key: 'QTY_Send' | 'QTY_Received') => {
    return transferDetails.reduce((total, detail) => total + (detail[key] || 0), 0);
  };

 // Filter the transfer details based on the searchValue and filterKey
 const filteredDetails = transferDetails.filter((detail) =>
    detail[filterKey].toString().toLowerCase().includes(searchValue.toLowerCase())
  );

  // Fetch aggregated data
  const totalQtySend = getTotalQty('QTY_Send');
  const totalQtyReceived = getTotalQty('QTY_Received');
  const [reprintQuantities, setReprintQuantities] = useState<{ [key: string]: number }>({});
  const handlePrintTags = async () => {
    try {
      // Iterate through each item and send the SKU and quantity to the backend API
      for (const item of filteredDetails) {
        const qty = reprintQuantities[item.PLU_SKU] || 0; // Default to 1 if no quantity is provided

        const response = await fetch('/print-label', {
          method: 'POST',
          credentials:'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sku: item.PLU_SKU, // SKU from the table
            qty : item.QTY_Send,               // Quantity to print
          }),
        });

        const data = await response.json();
        
        if (response.ok) {
          // You can log the ZPL to verify or send it to your printer
          console.log(`ZPL for ${item.PLU_SKU}:`, data.zpl);

          // Print logic (if using WebUSB or any other printing mechanism)
          // This is where you would send the ZPL to your printer
          sendZplToPrinter(data.zpl);
        } else {
          console.error(`Failed to print label for ${item.PLU_SKU}:`, data.message);
        }
      }
    } catch (error) {
      console.error('Error printing tags:', error);
    }
  };


  const sendZplToPrinter = (zpl: string) => {
    window.BrowserPrint.getLocalDevices((devices: any) => {
      console.log('Devices object:', devices);

      // Access the 'printer' array inside the 'devices' object
      if (devices && devices.printer && Array.isArray(devices.printer)) {
        const printers = devices.printer;

        console.log('Printers array length:', printers.length);

        // Check if there are any printers connected
        if (printers.length > 0) {
          printers.forEach((printer: any) => {
            console.log(`Printer: ${printer.name}, Connection: ${printer.connection}`);
          });

          // Find the USB-connected printer
          const printer = printers.find((device: any) => device.connection === 'usb');

          if (printer) {
            console.log('USB printer selected:', printer);
            // Now send ZPL to this printer
            printer.send(
              zpl,
              () => {
                //alert('Label sent to printer successfully.');
              },
              (error: string) => {
                console.error('Error sending ZPL to printer:', error);
                alert('Error sending label to printer: ' + error);
              }
            );
          } else {
            console.log('No USB-connected Zebra printer found.');
            alert('No USB-connected Zebra printer found.');
          }
        } else {
          console.log('No printers found.');
          alert('No printers found. Please check your printer connection.');
        }
      } else {
        console.error('No printers found in the devices object:', devices);
        alert('No printers found.');
      }
    }, 'printer');

  }

  return (
    <>
      <AppBar position="sticky">
        <NavBar />
      </AppBar>
      <Box sx={{paddingTop:0.3,height: '88vh', display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor:'#ccc' }}>
        {/* Top section: 30% - Aggregated Information */}
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
           paddingTop:1.5,
            borderRadius: '5px',
            height: '30%',
            overflowY: 'auto', // Make top section scrollable
            borderBottom: '1px solid #ccc',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Transfer #"
                variant="outlined"
                fullWidth
                value={transferDetails[0]?.Transfer_No || ''}
                size="small" // Reduces the size of the input field
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                  
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Sending Location"
                variant="outlined"
                fullWidth
                value={transferDetails[0]?.SendingLocation || ''}
                size="small"
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Receiving Location"
                variant="outlined"
                fullWidth
                value={transferDetails[0]?.receivingLocation || ''}
                size="small"
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Sent Date"
                variant="outlined"
                fullWidth
                size="small"
                value={new Date(transferDetails[0]?.Send_Date).toLocaleString() || ''}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Received Date"
                variant="outlined"
                fullWidth
                size="small"
                value={transferDetails[0]?.Received_Date ? new Date(transferDetails[0]?.Received_Date).toLocaleString() : ''}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Total Qty Sent"
                variant="outlined"
                fullWidth
                size="small"
                value={totalQtySend}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Total Qty Received"
                variant="outlined"
                fullWidth
                size="small"
                value={totalQtyReceived}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Status"
                variant="outlined"
                fullWidth
                size="small"
                value={transferDetails[0]?.Status || ''}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="List Notes"
                variant="outlined"
                fullWidth
                size="small"
                value={transferDetails[0]?.List_Notes || 'N/A'}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Notes"
                variant="outlined"
                fullWidth
                size="small"
                value={transferDetails[0]?.Notes || 'N/A'}
                InputProps={{
                  readOnly: true,
                  sx: { height: 36 }, // Customize the input height
                }}
                InputLabelProps={{ style: { fontWeight: 'bold',fontFamily: 'Arial', fontSize:'14pt' } }} // Bold label
              />
            </Grid>
          </Grid>
        </Box>
                 {/* Search Section */}
        <Box sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ccc', padding:1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography sx={{ fontWeight: 'bold', fontSize: '11pt' }}>Search by</Typography>
                <Select
                  value={filterKey}
                  onChange={(e) => setFilterKey(e.target.value as 'PLU_SKU' | 'Style_Code' | 'Size1_Code' | 'Description_1')}
                  size="small" // Reducing the size of the select component
                  sx={{ height: 36 }} // Minimum height
                >
                  <MenuItem value="PLU_SKU">SKU</MenuItem>
                  <MenuItem value="Style_Code">Style Code</MenuItem>
                  <MenuItem value="Size1_Code">Size</MenuItem>
                  <MenuItem value="Description_1">Description</MenuItem> {/* New filter option */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                size="small" // Reducing the size of the input field
                sx={{ height: 36, marginTop:'20px' }} // Minimum height
                InputLabelProps={{ style: { fontWeight: 'bold', fontSize: '14pt' } }} // Bold label with 12pt
              />
                     <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', marginTop:'20px', marginLeft: '50px' }}>
              {/* Adding the PrintTagsButton with the transferNo */}
              <Button variant="contained" color="primary" onClick={handlePrintTags}>
                Print Tags
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* Bottom section: 70% - Detailed SKU Information */}
        <Box sx={{ height: '80%', overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {['SKU', 'Description', 'Style Code', 'Color', 'Size', 'Qty Sent', 'Qty Received', 'Avg. Cost', 'Store Price'].map((header) => (
                  <TableCell key={header} sx={{ backgroundColor: '#FFFFF0', border: '1px solid #ccc', fontWeight: 'bold', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDetails.map((detail, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#FAF9F6' : '#D3D3D3',
                    '&:hover': {
                      backgroundColor: '#E0E0E0',
                    },
                  }}
                >
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.PLU_SKU}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.Description_1}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.Style_Code}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.Color_Code}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.Size1_Code}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.QTY_Send}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>{detail.QTY_Received}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>${detail.Cost}</TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>${detail.Sale_Price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

       
      </Box>
    </>
  );
};

export default TransferDetails;
