import React, { useState, useEffect } from "react";
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Table,
    styled,
} from "@mui/material";

interface TopSellerData {
    Location_ID: string;
    Style_Code: string;
    UnitSold: number;
    Description_1 : string;
}

// Define custom styled components
const StyledTableCell = styled(TableCell)({
    fontWeight: "bold", // Make text bold
  });
  
  const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5", // Light grey for odd rows
    },
    "&:hover": {
      backgroundColor: "#e0e0e0", // Slightly darker on hover
    },
  });
  
const TopSeller: React.FC = () => {
    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const formatDateWithTime = (date: Date, hours: number, minutes: number = 0) => {
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        return `${year}-${month}-${day}T${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
    };

    const getTodayDate = () => formatDateWithTime(new Date(), 3);
    const getFirstDateOfCurrentMonth = () => formatDateWithTime(new Date(new Date().setDate(1)), 3);
    const locationOptions = ["Steeles", "New-Scarborough", "Brampton", "Newmarket", "Dufferin-Orfus", "Vaughan","Queensway", "Niagara", "Alliston", "OPM-Hamilton", "Pickering", "Cartwright","Yorkgate"];
    const [dateFilter, setDateFilter] = useState<string>("today");
    const [startDate, setStartDate] = useState<string>(getFirstDateOfCurrentMonth());
    const [endDate, setEndDate] = useState<string>(getTodayDate());
    const [location, setLocation] = useState<string>("Steeles");
    const [top, setTop] = useState<number>(20);
    const [topSellerData, setTopSellerData] = useState<TopSellerData[]>([]);

    const fetchData = async () => {
        try {
            const requestData = {
                dateFilter,
                location,
                top,
                ...(dateFilter === "custom" && { startDate, endDate }),
            };

            const response = await fetch("/salesByBrand/topSeller", {
                method: "POST",
                credentials:'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            setTopSellerData(data.topSellerData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            fetchData();
        }, 5000);

        // Clear the timeout if dependencies change within 5 seconds
        return () => clearTimeout(debounceTimeout);
    }, [dateFilter, startDate, endDate, location, top]);

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
             {/* Centered Header */}
             
            <Grid container spacing={2}>
           
                <Grid item xs={12} md={6}>
                    
                <Grid item xs={12} md={14}>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 4, background:"#ff0028" }}>
                <Box sx={{ maxWidth: "300px", textAlign: "center" }}>
                    <Typography variant="h4" component="div" sx={{ fontWeight: "bold" }}>
                        Top Seller
                    </Typography>
                </Box>
            </Box>
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                        <FormControl
                            fullWidth
                            size={isXSmall ? "small" : "medium"}
                        >
                            <Select
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                                sx={{ height: "40px", width: "100%" }}
                            >
                                <MenuItem value="today">Today</MenuItem>
                                <MenuItem value="yesterday">Yesterday</MenuItem>
                                <MenuItem value="custom">Custom Dates</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Location Dropdown */}
                        <FormControl fullWidth size={isXSmall ? "small" : "medium"}>
                        <Select
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                sx={{
                                    height: "40px",
                                    width: "100%",
                                }}
                            >
                                {locationOptions.map((loc) => (
                                    <MenuItem key={loc} value={loc}>
                                        {loc}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Top Quantity Input */}
                        <FormControl fullWidth size={isXSmall ? "small" : "medium"}>
                        <TextField
                            type="text"
                            placeholder="Top Quantity"
                            value={top}
                            onChange={(e) => setTop(Number(e.target.value))}
                            InputProps={{ inputProps: { min: 1 } }} // Remove increment/decrement buttons
                             size="small"
                        />
                        </FormControl>
                    </Box>

                    {dateFilter === "custom" && (
                        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                            <TextField
                                label="Start Date"
                                type="datetime-local"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: "0.675rem" },
                                    "& .MuiInputLabel-root": { fontSize: "0.675rem" },
                                }}
                            />
                            <TextField
                                label="End Date"
                                type="datetime-local"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: "0.675rem" },
                                    "& .MuiInputLabel-root": { fontSize: "0.675rem" },
                                }}
                            />
                        </Box>
                    )}
                </Grid>

                {/* Table to display Top Seller Data */}
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: "400px", overflow: "auto" }}>
                        <Table stickyHeader>
                            <TableHead>
                                {/* First Header Row for Location, set sticky position */}
                                <StyledTableRow>
                                    <StyledTableCell
                                        colSpan={3}
                                        align="center"
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "#fff",
                                            zIndex: 2,
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        {`Top Sellers for ${location}`}
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Second Header Row for Style Code and Unit Sold, set sticky position */}
                                <StyledTableRow>
                                    <StyledTableCell
                                        sx={{
                                            position: "sticky",
                                            top: 42, // Keeps it just below the first header row
                                            backgroundColor: "#f9f9f9",
                                            zIndex: 1,
                                        }}
                                    >
                                        <b>Style</b>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{
                                            position: "sticky",
                                            top: 42,
                                            backgroundColor: "#f9f9f9",
                                            zIndex: 1,
                                        }}
                                    >
                                        <b>Desc</b>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{
                                            position: "sticky",
                                            top: 42,
                                            backgroundColor: "#f9f9f9",
                                            zIndex: 1,
                                        }}
                                    >
                                        <b>Unit Sold</b>
                                    </StyledTableCell>
                                   
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {topSellerData.map((item, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{item.Style_Code}</StyledTableCell>
                                        <StyledTableCell>{item.Description_1}</StyledTableCell>
                                        <StyledTableCell>{item.UnitSold}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </Grid>
        </Box>
    );
};

export default TopSeller;
